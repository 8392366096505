
import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * Modal where users can rename their file
 */
@Component({
  name: "UploadsRenameModal",
  components: {},
})
export default class RenameModal extends Vue {
  /**
   * Current name of the file
   */
  @Prop({ default: "" })
  private name!: string;
  private value: string = "";
  private dialogAux: boolean = true;

  private get dialog(): boolean {
    return this.dialogAux;
  }
  private set dialog(newValue: boolean) {
    if (!newValue) {
      this.cancel();
    }
    this.dialogAux = newValue;
  }

  private created() {
    this.value = this.name.split(".").slice(0, -2).join(".");
  }

  private confirm() {
    const suffix = this.name.split(".").slice(-2).join(".");
    /**
     * Update the file name
     *
     * @type {string}
     */
    this.$emit("confirm", `${this.value}.${suffix}`);
  }

  private cancel() {
    /**
     * Close the modal, don't update the filename
     */
    this.$emit("cancel");
  }
}
